import { combineReducers } from "redux"
import resultsReducer from "./ResultsStore/reducer"
import profileReducer from "./ProfileStore/reducer"

const allReducers = combineReducers({
  results: resultsReducer,
  profile: profileReducer,
})

export default allReducers
