import { SET_VALUES } from "./types"
import qs from "query-string"
// import get from "lodash/get"

const isDev = value => (process.env.NODE_ENV === "development" ? value : "")

export const initialState = {
  location: {},
  gallery: [],
  queryParams: qs.parse(
    typeof window !== "undefined" ? window.location.search : ""
  ),
  days: 1,
  holidays: [],
  selectedOptionsCategorized: [],
  selectedOptionsList: [],
  selectedOption: { id: "" },
  startDate: null,
  endDate: null,
  datesForDayPasses: [],
  numberOfDaysForDayPasses: 1,
  startTime: null,
  endTime: null,
  minDateGap: 1,
  minDate: new Date(),
  reservation: false,
  noOptionsAvailable: false,
  mobileOptionPicker: false,
  fromReservationPage: false,
  singleSpaceTeamSize: 0,
  user: {
    name: isDev("Mahesh Samudra"),
    email: isDev("mahesh.samudra@worksimply.com"),
    phone: isDev("7147147147"),
    couponCode: "",
  },
  couponDiscount: 0,
  couponDiscountPercentage: 0,
  displayMobileDatePicker: false,
  displayMobileDayPasses: false,
  pdfUrl: null,
  onDemandSearchInProgress: false,
  selectedOnDemandOption: {},
  noOnDemandOptionsAvailable: false,
  onDemandDaysHoursCount: 1,
  numberOfDayPasses: 1,
  teamSize: 0,
  itemUnit: ["day", "days"],
  checkoutPage: false,
  reservation_type: "on-demand-offices",
  isPerDayOnDemand: true,
  reservationStep: 1,
  backFromCheckoutPage: false,
  TAX_LABEL: null,
  TAX_PERCENTAGE: 0,
  contextMinDate: null,
  contextMaxDate: null,
  contextDate: null,
  contextEndDate: null,
  contextTime: null,
  availableContextTimes: [],
  lightBoxIndex: 0,
  openLightBox: false,
  showPerDayBooking: false,
  optionId: "",
  optionValue: 0,
  optionHstValue: 0,
  optionTotal: 0,
  optionPrice: 0,
  tentativeArrivalTime: "",
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VALUES:
      return {
        ...state,
        ...action.data,
      }

    default:
      return { ...state }
  }
}

export default userReducer
