import {
  SET_ACTIVE_SPACE,
  SET_HOLIDAYS,
  SET_RESULTS,
  SET_PROGRESS,
  INVALID_RESULTS,
  SET_OPERATING_HOURS,
} from "./types"

export const initialState = {
  list: null,
  refresh: true,
  inProgress: true,
  user: {},
  resultsLoaded: false,
  selected_space: null,
  mapCenter: { lat: 43.6508797, lng: -79.3834781 },
  activeId: "",
  holidays: [],
  openingTime: "9:00 AM",
  closingTime: "5:00 PM",
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROGRESS:
      return {
        ...state,
        inProgress: action.payload,
      }
    case SET_RESULTS:
      return {
        ...state,
        inProgress: false,
        resultsLoaded: true,
        list: action.list,
        user: action.user,
      }
    case INVALID_RESULTS:
      return {
        ...state,
        inProgress: false,
        list: [],
        resultsLoaded: true,
      }
    case SET_ACTIVE_SPACE:
      return {
        ...state,
        activeId: action.payload || "",
      }
    case SET_HOLIDAYS:
      return {
        ...state,
        openingTime: action.openingTime,
        closingTime: action.closingTime,
      }
    case SET_OPERATING_HOURS:
      return {
        ...state,
        holidays: action.payload || [],
      }

    default:
      return { ...state }
  }
}

export default userReducer
