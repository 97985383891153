// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-space-js": () => import("./../../../src/pages/space.js" /* webpackChunkName: "component---src-pages-space-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */)
}

