import React from "react"

import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import allReducers from "./src/stores/reducers"
import { Provider } from "react-redux"

import "./src/styles/reset.css"
import "./node_modules/bootstrap/dist/css/bootstrap-grid.min.css"
import "react-image-lightbox/style.css"
import "react-datepicker/dist/react-datepicker.css"

import "./src/styles/base.less"

const composeEnhancer =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)))

const WrapWithProviders = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

export default WrapWithProviders
